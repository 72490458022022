import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";

const TokenRoute = ({ children }: { children: React.ReactNode }) => {
    const { token } = useParams(); // Captura o token da URL
    const [isValid, setIsValid] = useState<boolean | null>(null);

    useEffect(() => {
        const validateToken = async () => {
            try {
                // Chamada ao backend para validar o token
                const response = await axios.get(`/api/shared-links/${token}`);
                if (response.status === 200) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            } catch (error) {
                console.error("Error validating token:", error);
                setIsValid(false);
            }
        };

        if (token) {
            validateToken();
        }
    }, [token]);

    if (isValid === null) {
        // Enquanto o token está sendo validado
        return <div>Loading...</div>;
    }

    if (!isValid) {
        // Se o token for inválido ou expirado, redireciona para uma página de erro ou outra rota
        return <Navigate to="/expired" />;
    }

    // Se o token for válido, renderiza o componente
    return <>{children}</>;
};

export default TokenRoute;