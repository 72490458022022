import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "styled-components";


export const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 3px;
`;


export const Spinner = styled.div`
   border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  margin-right: 5px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;


export const ContainerFlexInline = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
`;



export const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;

export const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`

export const ContainerSearch = styled.div`
  width:250px;
`
export const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
    gap: 5px;
`

export const ContainerSpan = styled.div`
padding: 6px;
`

interface ContainerTableProps {
  height?: string;
}

export const Container = styled.div`
  padding: 6px;
`;

export const ContainerTable = styled.div<ContainerTableProps>`
  padding: 6px;
  overflow: auto;
  height: ${({ height }) => height || "78vh"};
`;


export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 2px;
  padding: 4px;
  //height: calc(100vh - 220px);
  overflow: auto;
`;

export const BtnCheckBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const BtnCheckBoxOptions = styled.span<{ isZero?: boolean }>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isZero }) => (isZero ? '#888' : '#000')}; // Cinza quando isZero=true
  opacity: ${({ isZero }) => (isZero ? 0.6 : 1)}; // Adiciona opacidade para destacar visualmente
`;


export const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    display:flex;
    align-items: center;
    gap: 4px;
`;

export const InfoBoxLabel = styled.label`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px; /* Espaçamento entre o checkbox e o texto */
`;

export const MarginTop = styled.div`
  margin-top: 15px;
`;



// export const BtnFaIcon = styled.span`
//   margin-right: 8px;
//   font-size: 12px;
//   font-weight: 700;
//   cursor: pointer;
//   display: inline-flex;
//   align-items: center;
//   gap: 4px; // Adiciona espaço entre o ícone e o texto
// `;

type BtnFaIconProps = {
  iconColor?: string; // Permite que a cor seja opcional
};

export const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

export const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

export const PriorityItem = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const PointsItem = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;


export const SubmitButton = styled.button<{ isLoading?: boolean }>`
 
  color: ${(props) => (props.isLoading ? "#666" : "#1c7700")};
  padding: 10px 20px;
  border: 1px solid #1c7700;
  border-radius: 4px;
  cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 40px;

  &:hover {
    background-color: #1c7700;
    color: #fff;
  }

  &:disabled {
    background-color: #ddd;
    color: #666;
  }
`;


export const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;


export const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;


export const CancelButton = styled.button`
display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  color: #e53935;
  border: 1px solid #e53935;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  min-width: 120px;
  height: 40px;
  &:hover {
    background-color: #e53935;
    color: #fff;
  }
`;


export const ContainerInlinePicker = styled.div`
   display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
    margin-bottom: 5px;
    margin-top: 15px;
    width: 330px;
    z-index: 0;
    `;
