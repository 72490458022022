
const SharedComponent = () => {
    return (
        <div>
            <h1>Welcome to the Shared Component</h1>
            <p>This content is accessible via a valid token link.</p>
        </div>
    );
};


export default SharedComponent;