import React from 'react';
import styled from 'styled-components';
import TabButton from '../TabButton';
import { MapConfig } from '../../utilsMap/mapContext';
import ToolBarButtonsHistory from './ToolBarButtonsTabHistory';
import ToolBarIconsButtonHistory from './ToolBarIconsButtonHistory';
import ToolBarIconsButtonOneVehicle from './ToolBarIconsButtonOneVehicle';
import ToolBarIconsMapOpcoes from './ToolBarIconsMapOpcoes';
import { CREATE_FLAGS, HISTORY_VEHICLE, ONE_VEHICLE, STREAM_VEHICLE } from '../../utilsMap/ConstMaps';
import { selectUser } from '../../../../redux/userSlice';
import { useSelector } from 'react-redux';
import ToolBarIconsButtonSettings from './ToolBarIconsButtonSettings';
import ToolBarIconsButtonStream from './ToolBarIconsButtonStream';
import ToolBarIconsButtonFlags from './ToolBarIconsButtonFlags';

const ToolbarContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 16px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;


const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  .fa-icon {
    font-size: 24px;
    color: #474747;
  }
`;

const Toolbar: React.FC = () => {
  const { tabActive, modeActive, actionPressButton, updateConfig } = MapConfig();
  const { user } = useSelector(selectUser);


  const handleAlterTab = (tab: string) => {
    updateConfig({ tabActive: tab });
  };
  const handleSearch = (item: string) => {
    updateConfig({ searchVehicles: item });
  };

  const isShowMapOpcoes = () => {
    if (tabActive === 'MAPA' && modeActive !== CREATE_FLAGS) {
      return true;
    } else {
      return false
    }
  }



  return (
    <ToolbarContainer>
      <ActionButtons>
        {(modeActive === CREATE_FLAGS) && <ToolBarIconsButtonFlags />}
        {modeActive === HISTORY_VEHICLE && < ToolBarButtonsHistory />}
        {modeActive === HISTORY_VEHICLE && <ToolBarIconsButtonHistory />}
        {(modeActive === ONE_VEHICLE && tabActive === 'MAPA') && <ToolBarIconsButtonOneVehicle />}
        {(modeActive === ONE_VEHICLE && tabActive === 'SETINGS_VEHICLE') && <ToolBarIconsButtonSettings />}
        {(modeActive === STREAM_VEHICLE) && <ToolBarIconsButtonStream />}
        {/*<SearchField onSearch={handleSearch} />*/}
        {modeActive !== CREATE_FLAGS && (<><TabButton
          isActive={tabActive === 'MAPA'}
          onClick={() => handleAlterTab('MAPA')}
          title='MAPA'
        />
          <TabButton
            isActive={tabActive === 'REPORTS'}
            onClick={() => handleAlterTab('REPORTS')}
            title='RELATÓRIOS'
          /></>)}
        {/* <TabButton
          isActive={tabActive === 'SHARE_VEHICLES'}
          onClick={() => handleAlterTab('SHARE_VEHICLES')}
          title='COMPARTILHAR'
        /> */}
        {/*<TabButton
          isActive={tabActive === 'VEHICLES'}
          onClick={() => handleAlterTab('VEHICLES')}
          title='VEÍCULOS'
        />*/}
        {/*modeActive === ONE_VEHICLE && <TabButton
          isActive={tabActive === 'GALERIA'}
          onClick={() => handleAlterTab('GALERIA')}
          title='GALERIA'
        />*/}
        {(modeActive === ONE_VEHICLE && user.nivel === 2) && <TabButton
          isActive={tabActive === 'SETINGS_VEHICLE'}
          onClick={() => handleAlterTab('SETINGS_VEHICLE')}
          title='CADASTRO'
        />}
        {/* {modeActive === ONE_VEHICLE && <TabButton
          isActive={tabActive === 'ICONS'}
          onClick={() => handleAlterTab('ICONS')}
          title='ICONES'
        />} */}
        {/*modeActive === ONE_VEHICLE && <TabButton
          isActive={tabActive === 'COMANDOS'}
          onClick={() => handleAlterTab('COMANDOS')}
          title='COMANDOS'
        />*/}

      </ActionButtons>
      <ActionButtons>
        {isShowMapOpcoes() && < ToolBarIconsMapOpcoes />}
      </ActionButtons>
    </ToolbarContainer>
  );
};

export default Toolbar;
