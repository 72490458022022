

export const OPTIONS_TRAJETOS = 'OPTIONS_TRAJETOS'
export const SETTINGS_MAP = 'SETTINGS_MAP'
export const ALL_VEHICLES = 'ALL_VEHICLES';
export const ONE_VEHICLE = 'ONE_VEHICLE';
export const HISTORY_VEHICLE = 'HISTORY_VEHICLE';
export const STREAM_VEHICLE = 'STREAM_VEHICLE';
export const SETTINGS_CAR = 'SETTINGS_CAR';
export const TAB_HISTORY_ALERTS = 'TAB_HISTORY_ALERTS';
export const TAB_HISTORY_TRAJETOS = 'TAB_HISTORY_TRAJETOS';
export const TAB_HISTORY_EVENTS = 'TAB_HISTORY_EVENTS';
export const CREATE_FLAGS = 'CREATE_FLAGS';

