import React, { useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';

import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { ActionButtons, CancelButton, ErrorMessage, Spinner, SubmitButton } from '../../StylePages';
import { updateVehicleSettings } from '../repository/MapsRepo';
import { AxiosError } from 'axios';
import { extractErrorMessage } from '../../../utils/CustomErros';

// Definição do tipo
type VehicleSettings = {
    idvehicle: number;
    descr: string;
    odometer: number;
    horimeter: number;
};

// Estilos com styled-components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  margin-top: 16px;
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;


interface CompProps {
    vehicleData: VehicleData;
    onCancel: () => void;
    onUpdate: () => void;
}
const FormSettingsVehicles: React.FC<CompProps> = ({ vehicleData, onUpdate, onCancel }) => {
    const formRef = useRef<FormHandles>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorApi, setErrorApi] = React.useState<string | null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);

    // Schema de validação com yup
    const validationSchema = yup.object().shape({
        descr: yup.string().required('A descrição é obrigatória.'),
        odometer: yup
            .number()
            .required('O odômetro é obrigatório.'),
        horimeter: yup
            .number()
            .required('O horímetro é obrigatório.')
    });

    const handleSubmit = async (data: VehicleSettings) => {
        try {
            formRef.current?.setErrors({}); // Limpa erros anteriores

            await validationSchema.validate(data, { abortEarly: false }); // Valida os dados

            // Envia os dados para a API
            setIsLoading(true);
            setErrorApi(null);
            data.horimeter = data.horimeter * 3600;
            await updateVehicleSettings(data, vehicleData.idveiculo);
            onUpdate();

        } catch (err) {
            if (err instanceof AxiosError) {
                console.log(err);
                let message = extractErrorMessage(err)
                setErrorApi(message);
            }
            if (err instanceof yup.ValidationError) {
                const errors: Record<string, string> = {};
                err.inner.forEach((error) => {
                    if (error.path) errors[error.path] = error.message;
                });

                formRef.current?.setErrors(errors); // Seta os erros no formulário
            }
        } finally {
            setIsLoading(false);
            setErrorApi(null);
        }
    };

    const convertInHoursInt = (value: number) => {
        return Math.round(value / 3600);
    }


    useEffect(() => {
        if (vehicleData && initialLoad) {
            let register: VehicleSettings = {
                idvehicle: vehicleData.idveiculo,
                descr: vehicleData.apelido,
                odometer: Number(vehicleData.km_total.toFixed(0)),
                horimeter: convertInHoursInt(Number(vehicleData.HorimetroEquipTotal))
            };
            formRef.current?.setData(register);
            setInitialLoad(false);
        }
    }, [vehicleData, initialLoad]);

    return (
        <Container>
            <Form ref={formRef} onSubmit={handleSubmit} placeholder={''}>
                <InputFieldForm
                    label="Descrição"
                    name="descr"
                    placeholder="Descrição do veículo"
                    type="text"
                />
                <InputFieldForm
                    label="Odômetro (Km)"
                    name="odometer"
                    placeholder="Informe o odômetro atual"
                    type="number"
                />
                <InputFieldForm
                    label="Horímetro (H)"
                    name="horimeter"
                    placeholder="Informe o horímetro atual"
                    type="number"
                />
                {errorApi &&
                    <ErrorMessage>{errorApi}</ErrorMessage>
                }
                <ActionButtons>
                    <CancelButton type="button" onClick={onCancel}>Cancelar</CancelButton>
                    <SubmitButton type="submit" isLoading={isLoading} disabled={isLoading}>
                        {isLoading ? <>
                            <Spinner />
                            Salvando, aguarde...
                        </> : "Salvar"}
                    </SubmitButton>
                </ActionButtons>
            </Form>
        </Container>
    );
};

export default FormSettingsVehicles;
