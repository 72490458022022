import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { selectUser } from '../../../redux/userSlice';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { getApiOneVehicle, getApiVehicleMonthChart, getVehiclesByStream } from '../repository/MapsRepo';
import { styled } from 'styled-components';
import { MarkerClusterer } from "@googlemaps/markerclusterer";


import Sidebar from '../../../components/datagridComp/SideBar';
import OpcoesMap from '../components/OpcoesMap';
import { useConfig } from '../../../Config/configContext';
import IconButtonsMonitor from '../components/IconsButtonMonitor';
import { MapConfig } from '../utilsMap/mapContext';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { ALL_VEHICLES, CREATE_FLAGS, HISTORY_VEHICLE, ONE_VEHICLE, OPTIONS_TRAJETOS, SETTINGS_MAP, SETTINGS_CAR, STREAM_VEHICLE } from '../utilsMap/ConstMaps';
import ModalComp from '../../../components/datagridComp/ModalComp';
import CmdBlockComp from '../../ComandsCreator/views/CmdBlockComp';


import createAdvanceMarker from '../utilsMap/createAdvanceMarker';
import VehicleMapComp from '../components/VehicleMapComp';
import VehicleMapCompSmall from '../components/VehicleMapCompSmall';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import FilterList, { FilterVehicles } from '../components/FilterVehicles';
import { checkVehiclsHasOnline, prepareStatusFilter } from '../utilsMap/tools';
import VehicleMonthChart from '../components/GraphicsMap/VehicleMonthChart';
import VehiclePhotoComp from '../components/VehiclePhotoComp';
import StreamMapVehicle from '../components/StreamMapVehicle';

import { VariableSizeList } from 'react-window';
import { get } from 'http';
import { GeocoderService } from '../../../GoogleMaps/GeocoderService';
import VirtualFenceForm from '../components/VirtualFenceForm';
import FenceComp from '../components/FenceComp';
import FormSettingsVehicles from '../Forms/FormSettingsVehicles';
import { v4 } from 'uuid';

let intervalMap: any
let isInitMpp = true

const SummaryVehicles = styled.div`
    font-size: 12px;
    color: #666;
    padding-left: 5px;
    display: flex;
    align-items: start;
    width: 100%;
`;
// Container geral do layout
const LayoutContainer = styled.div`
  display: flex;
  height: 88vh;
`;

const LeftContainer = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '410px' : '328px')};
  padding-top: 5px;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 88vh;
  align-items: center;
  overflow: auto;
`;


const VehiclesContainer = styled.div`
 display: flex;
  gap   : 5px;
  height: 88vh;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const ContainerOverFlow = styled.div`
    overflow: auto;
    height: 88vh;
    `;

const ContainerMaps = styled.div`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s; /*Ajusta a margem dependendo do estado do menu */
`;

const MapContainer = styled.div`
  height: 100%; /* Garante que o mapa ocupe toda a altura do contêiner pai */
  width: 100%; /* Garante que o mapa ocupe toda a largura do contêiner pai */
  position: relative; /* Necessário para posicionar os marcadores */
`;

var actionConfig = ALL_VEHICLES
var idVehicleSel = 0
var markerOneVehicle: google.maps.marker.AdvancedMarkerElement | undefined;
let markerFlag: google.maps.Marker | null = null;
let clickListener: google.maps.MapsEventListener | undefined;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
`;

const MapTab: React.FC = () => {

    const { vehicleSelected, modeActive, actionPressButton, updateConfig } = MapConfig();
    //----------------Maps
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const markersVehicleRef = useRef<{ [id: string]: google.maps.marker.AdvancedMarkerElement }>({});
    const clustererRef = useRef<MarkerClusterer | null>(null);
    //--------------
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<VehicleData[]>([])
    const [filteredRows, setFilteredRows] = React.useState<VehicleData[]>([])
    const [configFilters, setConfigFilters] = React.useState<FilterVehicles[]>([])
    const [filterVehicles, setFilterVehicles] = React.useState<string[]>([]);
    const [findVehicles, setFindVehicles] = React.useState<string>('');
    const [vehicleMonthChart, setVehicleMonthChart] = React.useState<any[]>([]);
    const [isLoadingGraphics, setIsLoadingGraphics] = React.useState<boolean>(false);

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);
    //modal
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    async function getAllVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let resp: VehicleData[] = result;
        setRows(resp)
    }


    async function getVehicleMonthChart(idvehicle: number) {
        setIsLoadingGraphics(true)
        const result = await getApiVehicleMonthChart(idvehicle);
        setVehicleMonthChart(result)
        setIsLoadingGraphics(false)

    }

    useEffect(() => {
        if (rows.length === 0) return
        setConfigFilters(prepareStatusFilter(rows))
        handleSearch()
    }, [rows])

    async function getOneVehicle() {
        if (!idVehicleSel) {
            console.log("Vehicle Selected is null", idVehicleSel)
            return
        }
        const payload = {
            idvehicle: idVehicleSel,
        }
        const result = await getApiOneVehicle(payload);
        let resp: VehicleData = result;
        if (resp) {
            updateConfig({ vehicleSelected: resp })
        }

    }

    const handleSearch_remove = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleSearch = () => {
        let searchText = findVehicles
        const filtered = rows.filter((vehicle: VehicleData) => {
            const normalizedSearchText = searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            // Verifica se algum valor do veículo corresponde ao texto de busca
            const matchesSearchText = Object.values(vehicle).some((value) => {
                const normalizedValue = String(value)
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            });

            let isOnline = checkVehiclsHasOnline(vehicle.dtPing);
            let isMoving = vehicle.gpsvel > 0 && isOnline;
            let isBatteryCut = Number(vehicle.batveic || 0) < 2;

            const matchesStatusFilter =
                filterVehicles.length === 0 || // Se nenhum filtro foi passado, retorna todos
                (filterVehicles.includes("offline") && !isOnline) ||
                (filterVehicles.includes("move") && isMoving) ||
                (filterVehicles.includes("batcut") && isBatteryCut);

            return matchesSearchText && matchesStatusFilter;

        })


        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearch();
    }, [findVehicles]);

    useEffect(() => {
        handleSearch();
    }, [filterVehicles]);



    useEffect(() => {
        if (modeActive !== CREATE_FLAGS) {
            clearMap("UseEffect modeActive")
        }
        actionConfig = modeActive
        getDados()
    }, [modeActive]);


    async function getDados() {
        console.log("Get Dados", actionConfig)
        switch (actionConfig) {
            case ALL_VEHICLES:
                await getAllVehicles()
                break;
            case ONE_VEHICLE:
            case STREAM_VEHICLE:
                await getOneVehicle()
                break;
            default:
                console.log("Action not found")
                break;
        }
    }

    function getHistory() {
        clearMap('getHistory')
        updateConfig({ modeActive: HISTORY_VEHICLE, tabActive: HISTORY_VEHICLE })
    }




    function setStream() {
        idVehicleSel = vehicleSelected?.idveiculo || 0
        updateConfig({ modeActive: STREAM_VEHICLE, vehicleSelected: vehicleSelected })
    }

    const CtrlInterval = () => {
        intervalMap = setInterval(() => {
            getDados()
        }, 15000)

    }

    React.useEffect(() => {
        if (isInitMpp) {
            isInitMpp = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct MapBeta")
            clearInterval(intervalMap)
            isInitMpp = true;
            clearMap('Destruct')
        }
    }, [])

    useEffect(() => {
        console.log('Map IN Maps Open')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
            // Initialize MarkerClusterer
            clustererRef.current = new MarkerClusterer({ map });


        }
    }, [map]);

    useEffect(() => {
        const vehicles = filteredRows;
        if (mapContainerRef.current && vehicles.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            // Mapeia veículos pelo ID para facilitar comparações
            const newMarkers: { [id: string]: google.maps.marker.AdvancedMarkerElement } = {};

            // Função para atualizar markers de veículos
            const updateMarkers = () => {
                vehicles.forEach((vehicle) => {
                    const existingMarker = markersVehicleRef.current[vehicle.id];

                    if (existingMarker) {
                        // Se o veículo já existe no mapa, verificamos se a posição mudou
                        if (existingMarker.position?.lat !== Number(vehicle.gpslat) || existingMarker.position?.lng !== Number(vehicle.gpslng)) {
                            // Atualiza a posição do marker se mudou
                            existingMarker.position = new google.maps.LatLng(Number(vehicle.gpslat), Number(vehicle.gpslng));
                        }
                        // Mantemos esse marker no novo conjunto
                        newMarkers[vehicle.id] = existingMarker;
                        bounds.extend(existingMarker.position!);
                    } else {
                        // Se o veículo é novo, criamos um novo marker
                        //validar coordenadas
                        const lat = Number(vehicle.gpslat);
                        const lng = Number(vehicle.gpslng);
                        if (!isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0) {
                            const newMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
                                handleSelectVehicle(item);
                            });
                            newMarkers[vehicle.id] = newMarker;
                            bounds.extend(newMarker.position!);
                        }
                    }
                });

                // Remove markers de veículos que não estão mais presentes
                Object.keys(markersVehicleRef.current).forEach((id) => {
                    if (!newMarkers[id]) {
                        const marker = markersVehicleRef.current[id];
                        marker.map = null; // Remove o marker do mapa
                    }
                });

                // Atualiza o objeto de referência de markers com o novo estado
                markersVehicleRef.current = newMarkers;

                // Atualiza os markers no clusterer
                if (clustererRef.current) {
                    clustererRef.current.clearMarkers();
                    clustererRef.current.addMarkers(Object.values(newMarkers));
                }

                // Ajusta os limites do mapa para incluir todos os markers
                if (isFirstLoad) {
                    map!.fitBounds(bounds);
                    setIsFirstLoad(false);
                }
            };

            updateMarkers();
        }
    }, [filteredRows, isFirstLoad]);

    // useEffect(() => {
    //     const vehicles = filteredRows;
    //     if (mapContainerRef.current && vehicles.length > 0) {
    //         const bounds = new google.maps.LatLngBounds();

    //         // Mapeia veículos pelo ID para facilitar comparações
    //         const newMarkers: { [id: string]: google.maps.marker.AdvancedMarkerElement } = {};

    //         // Função para atualizar markers de veículos
    //         const updateMarkers = () => {
    //             vehicles.forEach((vehicle) => {
    //                 const existingMarker = markersVehicleRef.current[vehicle.id];

    //                 if (existingMarker) {
    //                     // Se o veículo já existe no mapa, verificamos se a posição mudou
    //                     if (existingMarker.position?.lat !== Number(vehicle.gpslat) || existingMarker.position?.lng !== Number(vehicle.gpslng)) {
    //                         // Remove o marker anterior do cluster e do mapa
    //                         if (clustererRef.current) {
    //                             clustererRef.current.removeMarker(existingMarker);
    //                         }
    //                         existingMarker.map = null; // Remove o marker do mapa

    //                         // Cria um novo marker na nova posição
    //                         const updatedMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
    //                             handleSelectVehicle(item);
    //                         });

    //                         // Atualiza a posição no novo conjunto de markers
    //                         newMarkers[vehicle.id] = updatedMarker;
    //                         bounds.extend(updatedMarker.position!);

    //                         // Adiciona o marker atualizado ao cluster
    //                         if (clustererRef.current) {
    //                             clustererRef.current.addMarker(updatedMarker);
    //                         }
    //                     } else {
    //                         // Se a posição não mudou, mantemos o marker existente
    //                         newMarkers[vehicle.id] = existingMarker;
    //                     }
    //                 } else {
    //                     // Se o veículo é novo, criamos um novo marker
    //                     const newMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
    //                         handleSelectVehicle(item);
    //                     });

    //                     newMarkers[vehicle.id] = newMarker;
    //                     bounds.extend(newMarker.position!);

    //                     // Adiciona o novo marker ao cluster
    //                     if (clustererRef.current) {
    //                         clustererRef.current.addMarker(newMarker);
    //                     }
    //                 }
    //             });

    //             // Remove markers de veículos que não estão mais presentes
    //             Object.keys(markersVehicleRef.current).forEach((id) => {
    //                 if (!newMarkers[id]) {
    //                     const markerToRemove = markersVehicleRef.current[id];
    //                     markerToRemove.map = null; // Remove o marker do mapa
    //                     if (clustererRef.current) {
    //                         clustererRef.current.removeMarker(markerToRemove); // Remove o marker do cluster
    //                     }
    //                 }
    //             });

    //             // Atualiza o objeto de referência de markers com o novo estado
    //             markersVehicleRef.current = newMarkers;

    //             // Ajusta os limites do mapa para incluir todos os markers
    //             if (isFirstLoad) {
    //                 map!.fitBounds(bounds);
    //                 setIsFirstLoad(false);
    //             }
    //         };

    //         updateMarkers();
    //     }
    // }, [filteredRows, isFirstLoad]);

    useEffect(() => {
        if (map && vehicleSelected) {
            clearMap('UseEffect vehicleSelected')
            markerOneVehicle = createAdvanceMarker(map!, vehicleSelected, () => { });
            map?.setCenter(markerOneVehicle.position!);
        }
    }, [vehicleSelected]);

    const handleSelectVehicle = (vehicle: VehicleData) => {
        clearMap('HandleSelectVehicle')
        idVehicleSel = vehicle.idveiculo
        updateConfig({ modeActive: ONE_VEHICLE, idVehicleSel: vehicle.idveiculo, vehicleSelected: vehicle })
        map!.setCenter({ lat: parseFloat(vehicle.gpslat), lng: parseFloat(vehicle.gpslng) });
        map!.setZoom(15);
        getVehicleMonthChart(vehicle.idveiculo)
    }

    const clearMap = (reason: String) => {
        console.log('ClearMap', reason);
        if (clickListener) {
            console.log('Removendo listener de clique no mapa');
            google.maps.event.removeListener(clickListener);
        }
        if (markerFlag) {
            markerFlag.setMap(null);
            markerFlag = null;
        }
        if (markerOneVehicle) {
            markerOneVehicle.map = null
            markerOneVehicle = undefined
        }
        if (markersVehicleRef.current) {
            Object.keys(markersVehicleRef.current).forEach((id) => {
                const marker = markersVehicleRef.current[id];
                marker.map = null;
            });
        }
        if (clustererRef.current) {
            clustererRef.current.clearMarkers();
        }
    }

    const handleClose = () => {
        clearMap('handleClose')
        idVehicleSel = 0
        updateConfig({ vehicleSelected: null, modeActive: ALL_VEHICLES })
        setIsFirstLoad(true)
    };


    const handleIconClick = (action: string) => {
        switch (action) {
            case 'close':
                handleClose()
                break;
            case 'lock':
                setIsModalOpen(true)
                break;
            case 'settings':
                setSideContent(OPTIONS_TRAJETOS)
                break;
            case 'settings-map':
                setSideContent(SETTINGS_MAP)
                break;
            case 'streetView':
                window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'map':
                window.open(`https://www.google.com/maps/search/?api=1&query=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'share':
                console.log('Compartilhar ação');
                break;
            case 'list':
                getHistory()
                break;
            case 'clear':
                clearMap('handleIconClick clear')
                break;
            case 'update-map':
                getDados()
                break;
            case 'open-fences':
                clearMap('open-fences')
                updateConfig({ modeActive: CREATE_FLAGS })
                break;
            case 'close-fences':
                handleClose()
                break;
            case 'stream':
                setStream()
                break;
            case 'settings-car':
                setSideContent(SETTINGS_CAR)
                break;


            default:
                console.log('Ação desconhecida');
        }
        updateConfig({ actionPressButton: null })
    };

    useEffect(() => {
        if (actionPressButton)
            handleIconClick(actionPressButton)
    }, [actionPressButton]);





    const menuTop = (
        <IconButtonsMonitor onIconClick={handleIconClick} mode={modeActive} />
    )

    const getItemSize = (index: number) => {
        let iddriver = filteredRows[index].DriverName
        if (iddriver) {
            return 115; // Altura maior para veículos específicos
        }
        return 98; // Altura padrão
    };

    return (
        <>
            <LayoutContainer>
                {modeActive === CREATE_FLAGS ?
                    <LeftContainer isExpanded={false}>
                        <FenceComp />
                    </LeftContainer> :
                    <LeftContainer isExpanded={modeActive === STREAM_VEHICLE}>
                        {!vehicleSelected &&
                            (<>
                                <SummaryVehicles>Exibindo {filteredRows.length} de {rows.length} Veículos</SummaryVehicles>
                                <SearchFieldV2 onSearch={setFindVehicles} searchTerm={findVehicles} />
                                <FilterList filters={configFilters} onFilterSelect={setFilterVehicles} />
                            </>)}
                        <VehiclesContainer>
                            {vehicleSelected ?
                                <>
                                    {modeActive === ONE_VEHICLE &&
                                        (<>
                                            <VehicleMapComp vehicleData={vehicleSelected} />
                                            <VehiclePhotoComp
                                                filename={vehicleSelected.vehicle_photo}
                                                idclient={user.idcliente}
                                                idempresa={vehicleSelected.id}
                                                idvehicle={vehicleSelected.idveiculo}
                                            />
                                            {isLoadingGraphics ? <LoadingSpinner>Carregando...</LoadingSpinner> : <VehicleMonthChart data={vehicleMonthChart} />}
                                        </>
                                        )}

                                    {modeActive === STREAM_VEHICLE &&
                                        (<>
                                            <VehicleMapComp vehicleData={vehicleSelected} />
                                            <StreamMapVehicle />
                                        </>)}


                                </>
                                : filteredRows.length ?
                                    <VariableSizeList
                                        height={516}
                                        itemCount={filteredRows.length}
                                        itemSize={getItemSize}
                                        width={329}
                                    >
                                        {({ index, style }) => (
                                            <div style={style} onClick={() => handleSelectVehicle(filteredRows[index])}>
                                                <VehicleMapCompSmall vehicleData={filteredRows[index]} />
                                            </div>
                                        )}
                                    </VariableSizeList> : <div>Nenhum veiculo</div>
                            }
                        </VehiclesContainer>
                    </LeftContainer>}
                <ContainerMaps>
                    <MapContainer ref={mapContainerRef}></MapContainer>
                </ContainerMaps>
            </LayoutContainer>


            <Sidebar isOpen={sideContent === SETTINGS_MAP} onClose={handleCloseSideBar} title='Configurações do Mapa'>
                <OpcoesMap />
            </Sidebar>
            <Sidebar isOpen={sideContent === SETTINGS_CAR} onClose={handleCloseSideBar} title='Configurações do Veículo'>
                {vehicleSelected && <FormSettingsVehicles
                    onCancel={handleCloseSideBar}
                    onUpdate={getDados}
                    vehicleData={vehicleSelected}
                />}
            </Sidebar>
            {(isModalOpen && vehicleSelected) && (
                <ModalComp title="Comando de Bloqueio" subtitle="" onClose={() => { setIsModalOpen(false) }}>
                    <CmdBlockComp
                        onClose={() => { setIsModalOpen(false) }}
                        vehicleData={vehicleSelected}
                    />
                </ModalComp>
            )}
        </>
    );
};

export default MapTab;
